<template>
  <div id="#app">
    <v-app class="d-flex flex-column">
      <Header v-if="getHeaderSwitch" />

      <v-main class="aiz-main-wrap">
        <router-view
          :key="
            ['ShopDetails', 'ShopCoupons', 'ShopProducts'].includes($route.name)
              ? null
              : $route.path
          "
        ></router-view>
      </v-main>

      <Promise v-if="getPromiseSwitch" />
      <Footer
        v-if="getFooterSwitch"
        :class="['mt-auto', { 'd-none': routerLoading }]"
      />

      <!-- <BottomChat /> -->
      <SidebarCart v-if="getSidebarCartSwitch" />
      <AddToCartDialog v-if="getAddToCartDialogSwitch" />
      <LoginDialog v-if="!isAuthenticated" />
      <MobileMenu v-if="getMobileMenuSwitch" class="d-lg-none" />
      <SnackBar />
    </v-app>
  </div>
</template>

<script>
import Header from '@/components/header/Header'
import Footer from '@/components/footer/Footer'
import SidebarCart from '@/components/cart/SidebarCart'
// import BottomChat from '@/components/inc/BottomChat'
import SnackBar from '@/components/inc/SnackBar'
import MobileMenu from '@/components/inc/MobileMenu'
import LoginDialog from '@/components/auth/LoginDialog.vue'
import AddToCartDialog from '@/components/product/AddToCartDialog'
import Promise from '@/components/footer/Promise'

import { mapGetters, mapActions, mapMutations } from 'vuex'

export default {
  metaInfo() {
    return {
      title: this.appMetaTitle
    }
  },
  components: {
    Header,
    Footer,
    // BottomChat,
    SidebarCart,
    SnackBar,
    LoginDialog,
    MobileMenu,
    AddToCartDialog,
    Promise
  },
  computed: {
    ...mapGetters('layout', [
      'getHeaderSwitch',
      'getPromiseSwitch',
      'getFooterSwitch',
      'getSidebarCartSwitch',
      'getAddToCartDialogSwitch',
      'getMobileMenuSwitch'
    ]),
    ...mapGetters('auth', ['isAuthenticated']),
    ...mapGetters('app', [
      'appMetaTitle',
      'userLanguageObj',
      'routerLoading',
      'siteIcon'
    ])
  },
  methods: {
    ...mapActions('auth', ['getUser', 'checkSocialLoginStatus']),
    ...mapActions('app', ['fetchSetting']),
    ...mapMutations('auth', ['setSociaLoginStatus', 'setAccessToken']),
    changeRTL() {
      if (this.userLanguageObj && this.userLanguageObj.rtl == 1) {
        this.$vuetify.rtl = true
      } else {
        this.$vuetify.rtl = false
      }
    }
  },
  async created() {
    const href = window.location.href

    if (href.includes('access_token')) {
      const access_token = href.split('=')[1]

      this.setAccessToken(access_token)
    }

    // cwjd
    this.$store.dispatch('app/fetchIdSwitch')
    
    await this.fetchSetting()
    this.changeRTL()

    await this.getUser()
    setTimeout(() => {
      this.checkSocialLoginStatus()
    }, 200)

    const faviconIcon = document.querySelector('link[rel=icon]')

    if (faviconIcon) {
      faviconIcon.href = this.addResourceImage(this.siteIcon)
    }
  }
}
</script>
<style lang="scss">
@import '@/styles/_variables.scss';
@import '@/styles/app.scss';
@import '@/styles/main.scss';

// #app {
//   font-family: Avenir, Helvetica, Arial, sans-serif;
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
//   text-align: center;
//   color: #2c3e50;
// }

.absolute-full {
  background: #fff;
  z-index: 10000;
}
</style>
